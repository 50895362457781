import { Pipe, PipeTransform } from '@angular/core'
import numeral from 'numeral'

@Pipe({
  name: 'numeral'
})
export class NumeralPipe implements PipeTransform {
  transform(value: number, format: string, zeroValue: string = '$0.00'): any {
    if (!value) {
      return zeroValue
    }
    return numeral(value).format(format)
  }
}
