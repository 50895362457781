import { ConfigService } from './config.service'
import {
  Appearance,
  loadStripe,
  Stripe,
  StripeConstructorOptions
} from '@stripe/stripe-js'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  constructor(private configSvc: ConfigService) {}

  public getElementsAppearance(): Appearance {
    // use any for this because for some reason Stripe's types don't know about the flat theme
    const theme: any = 'flat'

    return {
      theme,
      variables: {
        colorBackground: '#f3f5f6',
        colorText: '#525f7f',
        colorDanger: '#fb6340',
        fontFamily: '"Open Sans", sans-serif',
        borderRadius: '4px'
      }
    }
  }

  async initStripe(org?: any): Promise<Stripe> {
    const opts: StripeConstructorOptions = {}

    if (org) {
      const integrations = org?.integrations || []
      const stripeIntegration = integrations.find(
        (i: any) => i.id === 'stripe' && i?.connection?.status === 'installed'
      )
      const stripeSettings = stripeIntegration?.connection?.settings || []

      opts.stripeAccount = stripeSettings.find(
        (setting: any) => setting.key === 'accountId'
      )?.value
    }

    const stripe = await loadStripe(this.configSvc.config.stripePublicKey, opts)

    return stripe
  }
}
